//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-620:_2952,_1484,_1468,_5740,_9696,_5020,_5732,_6572;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-620')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-620', "_2952,_1484,_1468,_5740,_9696,_5020,_5732,_6572");
        }
      }catch (ex) {
        console.error(ex);
      }